@import "palettes";
@import "theme";
@import "mixins";

.gallery {
  display: grid;
  grid-gap: 30px;
  justify-content: center;
  align-items: center;
  margin: 40px 0;
  @include trim-v-margins;

  .gallery_item {
    // box-shadow: $shadow;
  }

  &[data-style="square"] {
    .gallery_item {
      position: relative;

      &:before {
        content: "";
        display: block;
        padding-top: 100%;
      }

      img {
        position: absolute;
        left: 0;
        top: 0;
        object-fit: cover;
        min-width: 100%;
        min-height: 100%;
      }
    }
  }

  @media (min-width: $tablet) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: $tablet) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: $phone) {
    grid-template-columns: 1fr !important;
  }

  &[data-number="2"] {
    grid-template-columns: 1fr 1fr;
  }

  &[data-number="1"] {
    grid-template-columns: 1fr;
  }
}
